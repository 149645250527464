@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,900;1,400;1,900&family=Raleway:wght@500;700&family=Montserrat:wght@500;700&display=swap);
body {
  background-color: #fff;
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: content-box;
  color: #36393B;
}

h1 {
  margin-top: 0px;
  margin-bottom: 64px;
}
h2 {
  margin: 24px 0;
}
h4 {
  margin: 24px 0 12px 0;
}

img {
  max-width: 100%;
}

p {
  margin: 24px 0;
}
.fa-calc-results p {
  margin: 12px 0;
}
.big-text {
  font-size: 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
}
li a {
  color: inherit;
}

section {
  border-bottom: 1px solid #E9EDEF;
  padding: 24px 0;
}

hr {
  border-bottom: 1px solid rgb(233, 237, 239);
  margin: 12px 0px;
}

/* Mobile Menu */
ul[role="menu"] li.active {
  background-color: rgba(0, 0, 0, 0.08);
}

ul[role="menu"] li.inactive {
  background-color: #fff;
}

/* Theme Specific Css */
.page-container {
  padding: 100px 0 0 0;
  max-width: 1032px;
  margin: 0 auto;
}

.full-body-bg {
  background: #F4F6F7;
  height: 100vh;
  overflow-y: hidden;
  position: fixed;
  width: 100%;
  z-index: -2
}

.banner {
  position: absolute;
  width: 100%;
  height: 180px;
  background: linear-gradient(129.03deg, #0D649C -7.03%, #BEE0EB 260.77%);
  z-index:-1;
}

#home-page-banner {
  display: none;
}

.home-card h1 {
  color: #36393B;
  font-size: 44px;
  line-height: 56px;
  max-width: 640px;
  margin-bottom: 0px;
}

.home-card p {
  color: #6F787D;
  font-size: 24px;
  line-height: 36px;
  max-width: 440px;
}

.home-page-card--small {
  height: auto;
}

.home-page-card--small a {
  font-weight: bold;
}

.fa-select:before {
  border-bottom: none !important;
}
.household-size-number label {
  display: none !important;
}
.household-size-number input {
  border: none !important;
}

p.tiny-text {
  font-size: 14px;
}

/* myriad theme */
.myriad-theme h1 {
  color: #fff;
}

.myriad-theme a {
  color: #055C94;
}

.myriad-theme .home-card {
  background: #FFF url(/static/media/myriad-cta-mask.e155342c.svg) no-repeat 96% bottom;
}
.myriad-theme .home-page-card--small {
  border-top: 4px solid #08A2D3;
}

/* neuro theme (Genesight) */
.neuro-theme a {
  color: #007DB3;
}

.neuro-theme .banner {
  background: #F4F6F7 url(/static/media/genesight-mask.a3718ea7.svg) no-repeat right top;
  background-size: 30% 180px;
}
.neuro-theme h1, .neuro-theme h2 {
  font-family: 'Raleway', sans-serif;
}

.neuro-theme .home-card {
  background: #FFF url(/static/media/genesight-cta-mask.5b11131f.svg) no-repeat 96% 118%;
}
.neuro-theme .home-page-card--small {
  border-top: 4px solid #7AC142;
}

/* autoImmune theme (Crescendo) */
.autoImmune-theme h1 {
  color: #fff;
}
.autoImmune-theme a {
  color: #6C2888;
}

.autoImmune-theme .banner {
  background: linear-gradient(129.03deg, #6C2888 -7.03%, #E08433 260.77%);
}

.autoImmune-theme h1, .autoImmune-theme h2 {
  font-family: 'Montserrat', sans-serif;
}

.autoImmune-theme .home-card {
  background: #FFF url(/static/media/vectra-cta-mask.cd04d9ef.svg) no-repeat 96% bottom;
}

.autoImmune-theme .home-page-card--small {
  border-top: 4px solid #E08433;
}

/* media queries */
@media (max-width: 1280px) {
  .page-container {
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .page-container {
    padding: 38px 0 0 0;
    max-width: 767px;
    margin: 64px 16px 0 16px;
  }

  .page-container.page-container--home {
    margin-top: 0px;
  }

  #home-page-banner {
    display: block;
  }
  .myriad-theme .home-card {
    background: #FFF url(/static/media/myriad-cta-mask-mobile.23b5d0f7.svg) no-repeat 90% 100%;
    background-size: 400px;
  }

  .neuro-theme  .home-card {
    background: #FFF url(/static/media/genesight-mask-mobile.a3850e68.svg) no-repeat 100% 120%;
    background-size: 400px;
  }
  
  .autoImmune-theme .home-card {
    background: #FFF url(/static/media/vectra-cta-mask-mobile.f36460f2.svg) repeat-x 300% 220%;
  }

  .home-card > div {
    padding: 28px 16px;
  }
  .home-card > div a {
    align-self: flex-start !important; /* important because css in js */
  }
  .big-text {
    font-size: 20px;
  }
}

@media (max-width: 482px) {
  .neuro-theme .home-card {
    background: #FFF url(/static/media/genesight-mask-mobile.a3850e68.svg) no-repeat 100% 120%;
    background-size: 300px;
  }

  .autoImmune-theme .home-card {
    background: #FFF url(/static/media/vectra-cta-mask-mobile.f36460f2.svg) repeat-x 300% 170%;
  }
}

/* iframe styles */
#financial-assistance-iframe, #contact-us-iframe {
  overflow: hidden;
  width: 100%;
  border: none;
}

#financial-assistance-iframe {
  height: 951px;
}

#contact-us-iframe {
  height: 1081px;
}

@media (max-width: 767px) {
  /* overwrite some modal styles */
  .ReactModal__Content {
    top: 0px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    border: none !important;
    height: 100vh !important;
  }
}

@media (max-width: 979px) {
  .faqs-sections {
    max-width: 100%;
  }
}

@media (min-width: 979px) {
  .faqs-sections {
    max-width: 80%;
  }
}

.footer-section {
  margin: 10px 0px 10px 0px;
  padding: 0px !important;
}

h1 sup {
  font-size: 60%;
}

